import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    app: {
      drawer: false,
      regionDialog: false,
      title: null,
      locale: null,
      localeSetByUser: false,
      channel: null,
      currency: null,
      menuItems: null,
      avatarDialog: false,
    },
    products: [],
    newProducts: [],
    featuredProducts: [],
    customer: null,
    checkout: {
      cart: null,
      shippingRates: null,
      paymentMethods: null,
      billingAddress: {
        address: null,
        isShippingAddress: true
      },
      shippingAddress: {
        address: null
      },
    },
    order: null,
    invoice: null,
    layoutDesigner: {
      currentLayout: null
    },
    user: {
      firstName: null,
      lastName: null,
      avatars: [],
      avatar: null // The currently selected avatar
    },
    userAuthenticated: false,
    authToken: null,
    admin: {
      orders: []
    }
  },
  mutations: {
    mutateAppTitle(state, title) {
      state.app.title = title;
    },
    mutateDrawer(state, value) {
      state.app.drawer = value;
    },
    mutateRegionDialog(state, value) {
      state.app.regionDialog = value;
    },
    mutateLocaleSetByUser(state, value) {
      state.app.localeSetByUser = value;
    },
    mutateCustomer(state, customer) {
      state.customer = customer;
    },
    mutateUserAuthenticated(state, userAuthenticated) {
      state.userAuthenticated = userAuthenticated;
    },
    mutateAuthToken(state, token) {
      state.authToken = token;
    },
    mutateChannel(state, channel) {
      state.app.channel = channel;
    },
    mutateLocale(state, locale) {
      state.app.locale = locale;
    },
    mutateCurrency(state, currency) {
      state.app.currency = currency;
    },
    mutateMenuItems(state, menuItems) {
      state.app.menuItems = menuItems;
    },
    mutateCart(state, cart) {
      state.checkout.cart = cart;
    },
    mutatebillingAddress(state, address) {
      state.checkout.billingAddress.address = address;
    },
    mutateBillingAddressIsShipppingAddress(state, isShippingAddress) {
      state.checkout.billingAddress.isShippingAddress = isShippingAddress
    },
    mutateshippingAddress(state, address) {
      // method name isn't camel case intentionally!
      state.checkout.shippingAddress.address = address;
    },
    mutateShippingRates(state, shippingRates) {
      state.checkout.shippingRates = shippingRates;
    },
    mutatePaymentMethods(state, paymentMethods) {
      state.checkout.paymentMethods = paymentMethods;
    },
    mutateOrder(state, order) {
      state.order = order;
    },
    mutateInvoice(state, invoice) {
      state.invoice = invoice;
    },
    mutateLayoutDesignerCurrentLayout(state, layout) {
      state.layoutDesigner.currentLayout = layout;
    },
    mutateAddAvatarToAvatarsCollection(state, avatar) {
      state.user.avatars.push(avatar);
    },
    mutateAvatar(state, avatar) {
      state.user.avatar = avatar;
    },
    mutateAvatarDialog(state, toggle) {
      state.app.avatarDialog = toggle;
    },

    mutateAdminOrders(state, orders) {
      state.admin.orders = orders;
    },
    mutateProducts(state, products) {
      state.products = products;
    },
    mutateNewProducts(state, products) {
      state.newProducts = products;
    },
    mutateFeaturedProducts(state, products) {
      state.featuredProducts = products;
    }
  },
  getters: {
    getProductByURLKey: (state) => (url_key) => {
      return state.products.find(product => product.url_key === url_key)
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
});
