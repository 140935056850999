import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index.js'

Vue.use(VueRouter);

const routes = [
    {
      path: '/',
      name: 'home',
      meta: {
        keepAlive: true,
        components: {
          TextBlock: true
        },
        title: 'Houstead Studio',
        metaTags: [
          {
            name: 'description',
            content: 'Scandinavian - Nordic design posters at great prices'
          },
          {
            property: 'og:title',
            content: 'Houstead Premium Posters'
          },
          {
            property: 'og:description',
            content: 'Scandinavian - Nordic design posters at great prices'
          },
          {
            property: 'og:image',
            content: 'https://cdn.houstead.com/content/main-image-november-2020.jpg'
          }
        ]
      },
      // route level code-splitting
      // this generates a separate chunk (home.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    },
    {
      path: '/:locale',
      name: 'home-locale',
      meta: {
        title: 'Houstead Studio',
        keepAlive: true,
        components: {
          TextBlock: true
        },
        metaTags: [
          {
            name: 'description',
            content: 'Scandinavian - Nordic design posters at great prices'
          },
          {
            property: 'og:title',
            content: 'Houstead Premium Posters'
          },
          {
            property: 'og:description',
            content: 'Scandinavian - Nordic design posters at great prices'
          },
          {
            property: 'og:image',
            content: 'https://cdn.houstead.com/content/main-image-november-2020.jpg'
          }
        ]
      },
      // route level code-splitting
      // this generates a separate chunk (home.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    },
    {
      path: '/:locale/page/:slug',
      name: 'page',
      meta: {
        keepAlive: true,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (page.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "page" */ '../views/Page.vue'),
    },
    {
      path: '/:locale/page/about',
      name: 'about',
      meta: {
        keepAlive: true,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
      path: '/:locale/product/:url_key',
      name: 'product-details',
      meta: {
        keepAlive: true,
        components: {
          TextBlock: true
        }
      },
      // route level code-splitting
      // this generates a separate chunk (product-details.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "product-details" */ '../views/ProductDetails/index.vue'),
    },
    {
      path: '/:locale/collections',
      name: 'category-index',
      meta: {
        keepAlive: true,
        components: {
          TextBlock: true
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "category-index" */ '../views/Categories.vue'),
    },
    {
      path: '/:locale/collection/:category_slug',
      name: 'category',
      meta: {
        keepAlive: true,
        components: {
          TextBlock: true
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "category-index" */ '../views/CategoryIndex.vue'),
    },
    {
      path: '/:locale/cart',
      name: 'cart',
      meta: {
        keepAlive: false,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "cart" */ '../views/Cart.vue'),
    },
    {
      path: '/:locale/order/confirmation',
      name: 'OrderConfirmation',
      meta: {
        keepAlive: false,
        requiresAuth: true,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "OrderConfirmation" */ '../views/checkout/OrderConfirmation.vue'),
    },
    {
      path: '/:locale/customer/login',
      name: 'login',
      meta: {
        keepAlive: false,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "login" */ '../views/user/Login.vue'),
    },
    {
      path: '/:locale/customer/profile',
      name: 'profile',
      meta: {
        keepAlive: false,
        requiresAuth: true,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "profile" */ '../views/user/Profile.vue'),
    },
    {
      path: '/admin/dashboard',
      name: 'admin.dashboard',
      meta: {
        keepAlive: true,
        admin: true,
        requiresAuth: true,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "profile" */ '@/packages/Admin/src/resources/views/Dashboard.vue'),
    },
    {
      path: '/admin/order/view/:orderID',
      name: 'admin.order.view',
      meta: {
        keepAlive: true,
        admin: true,
        requiresAuth: true,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin.order.view" */ '@/packages/Admin/src/resources/views/Order/View.vue'),
    },
    {
      path: '/admin/login',
      name: 'admin.login',
      meta: {
        keepAlive: true,
        requiresAuth: false,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin.order.view" */ '@/packages/Admin/src/resources/views/Auth/Login.vue'),
    },
    {
      path: '/avatar/designer',
      name: 'avatarDesigner',
      meta: {
        keepAlive: true,
        requiresAuth: false,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "avatar.designer" */ '@/packages/Avatar/src/resources/views/AvatarDesigner/index.vue'),
    }
];

const router = new VueRouter({
  mode: 'history',
  routes,
    scrollBehavior (to, from, savedPosition) {
    let position = { x: 0, y: 0 }
    // Keep scroll position when using browser buttons
    if (savedPosition) {
      position = savedPosition
    }

    // Workaround for transitions scrolling to the top of the page
    // However, there are still some problems being fixed by the vue team
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(position)
      }, 200)
    })
  }
});


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

  if (to.matched.some(m => m.meta.requiresAuth) && !store.state.userAuthenticated) {

    if (to.matched.some(m => m.meta.admin)) {
      return next({ path: '/admin/login?redirect=' + to.fullPath })
    } else {
      return next({ path: '/customer/login?redirect=' + to.fullPath })
    }
  }


  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  return next();
});

export default router;
