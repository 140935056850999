import store from '../store/index.js'
import i18n from '../plugins/i18n.js'

export default {
   getChannelByCode (channelCode) {
      return axios.post(process.env.VUE_APP_ROOT_API + '/getChannelByCode', {
          code: channelCode
      }
      ).then(response => {
        store.commit('mutateChannel', response.data.data);
        if (store.state.app.currency == null) {
          store.commit('mutateCurrency', response.data.data.base_currency);
        }

        if (store.state.app.locale === null) {
          store.commit('mutateLocale', response.data.data.default_locale);
          i18n.locale = response.data.data.default_locale
        }

        return response.data.data;
      }).catch(error => {
        console.log(error);
      })
  }
}
