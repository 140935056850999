<template>
  <v-app id="app" >

    <nav-drawer></nav-drawer>

    <top-bar></top-bar>


    <v-main :style="'background-color:' + this.$vuetify.theme.currentTheme.background.base + '; color:' + this.$vuetify.theme.currentTheme.onBackground + ';'">
      <v-banner :color="this.$vuetify.theme.currentTheme.background.base">
        <v-row>
          <v-col sm="12" offset-lg="2" lg="8" align="center">
            <div>
              <div v-if="channel" v-html="channel.header_message">

              </div>
            </div>
               <!-- - select region -->

          </v-col>
        </v-row>
      </v-banner>


      <v-banner :color="this.$vuetify.theme.currentTheme.primary.base"   :style="'color:' + this.$vuetify.theme.currentTheme.onPrimary + ';'" :sticky="true"  :app="true">
        <v-row>
          <v-col sm="12" offset-lg="2" lg="8" align="center">
            <!-- Right now: 15% off any poster!
            <br>
            ends at midnight -->
            <div v-if="channel" v-html="channel.promotional_message">

            </div>
          </v-col>
        </v-row>
      </v-banner>
      <br>

      <v-container style="min-height: 100vh">

      <transition name="fade-transition" mode="out-in" @after-enter="afterEnter" appear>
        <keep-alive :key="$route.fullPath">
          <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
        </keep-alive>
      </transition>

      <transition name="fade-transition" mode="out-in" @after-enter="afterEnter" appear>
        <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"></router-view>
      </transition>

      </v-container>


      <br>
      <br>

      <v-container v-if="$route.meta.components">
        <v-row v-if="$route.meta.components.TextBlock">
          <v-col offset-xs="1" xs="10" offset-sm="2" sm="8" offset-lg="2" lg="8" class="pt-0 pb-0">

              <article>
                <h3>Our Story</h3>
                <br>
                <div>
                  Some images here
                </div>
                <p>
                  This is our story.
                  <br>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                  <br>
                  <a href="#" style="color:darkblue;">Read more..</a>
                </p>
              </article>

              <article>
                <h4>Japanese Art Posters/Prints</h4>
                <br>
                <div>
                  Some images here
                </div>
                <p>
                  This is a keyword-filled SEO text.
                  <br>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </article>


              <article>
                <h4>Vintage Prints</h4>
                <br>
                <div>
                  Some images here
                </div>
                <p>
                  This is a another keyword-filled SEO text.
                  <br>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </article>

            <!-- </div> -->
          </v-col>
        </v-row>
      </v-container>

    </v-main>

    <v-dialog
      v-model="regionDialog"
      v-if="regionDialog === true"
      :hide-overlay="true"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Select Region
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" align="center">
              <img src="/img/flags/european-union.svg" alt="european-union-flag-select-region" style="cursor:pointer;" @click="selectRegion('en_EU', 'EUR')">
              <span>European Union</span>
            </v-col>
            <v-col cols="6" align="center">
              <img src="/img/flags/united-states.svg" alt="european-union-flag-select-region" style="cursor:pointer;" @click="selectRegion('en_US', 'USD')">
              <span>United States</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" align="center">
              <img src="/img/flags/united-kingdom.svg" alt="european-union-flag-select-region" style="cursor:pointer;" @click="selectRegion('en_GB', 'GBP')">
              <span>United Kingdom</span>
            </v-col>
            <!-- <v-col cols="6" align="center">
              <img src="/img/flags/united-states.svg" alt="european-union-flag-select-region" style="cursor:pointer;" @click="selectRegion('en_US', 'USD')">
              <span>United States</span>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-card-actions>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-footer
      color="lightgrey"
      style="border-top:1px solid lightgrey; z-index:9999;"
      app
      :absolute="true"
    >
    <v-container>
      <!-- <v-row>
        <v-col offset-xl="4" offset-lg="4" offset-md="3"  xl="2" lg="2" md="3" sm="6" >
          <v-select
            v-if="applicationLocale"
            :items="locales"
            v-model="applicationLocale"
            item-value="code"
            item-text="name"
            label="Country/Language"
            :hide-details="true"
            dense
            flat
          ></v-select>
        </v-col>
        <v-col xl="2" lg="2" md="3" sm="6">
          <v-select
            v-if="applicationCurrency"
            :items="currencies"
            v-model="applicationCurrency"
            item-value="code"
            item-text="name"
            label="Currency"
            :hide-details="true"
            dense
            flat
          ></v-select>
        </v-col>
        <v-btn @click="reload()" flat outlined>
          switch
        </v-btn>
      </v-row> -->

      <v-row>
        <v-col cols="12" align="center">
          <span >&copy;{{currentYear()}} <span v-if="this.$store.state.app.channel">{{this.$store.state.app.channel.name}}</span>&trade;</span>
        </v-col>
      </v-row>
    </v-container>

    </v-footer>
    <!-- <avatar-dialog></avatar-dialog> -->
  </v-app>
</template>

<script>
import store from '@/store/index.js'
import CustomerService from "@/services/CustomerService.js"
import CategoryService from "@/services/CategoryService.js"
import ChannelService from "@/services/ChannelService.js"

import TopBar from "@/components/TopBar"
import NavDrawer from "@/components/NavDrawer"


// import AvatarDialog from "@/components/AvatarDialog"

  export default {
    components: {
      // AvatarDialog
      TopBar,
      NavDrawer
    },
    props: {
      source: String,
    },
    data: () => ({
      ready: false,
      drawer: false,
      items: [],
      pages:[
        {
          name: 'About Us',
          path: '/about',
          content: ''
        },
        {
          name: 'Terms',
          path: '/terms',
          content: ''
        }
      ],
    }),
    computed: {
      channel: function () {
        if (this.$store.state.app.channel) {
          return this.$store.state.app.channel;
        } else {
          return null;
        }
      },
      applicationCurrency: function () {
        if (this.$store.state.app.currency) {
          return this.$store.state.app.currency.code;
        } else {
          return null;
        }
      },
      applicationLocale: {
        // getter
        get: function () {
          if (this.$store.state.app.locale) {
            return this.$store.state.app.locale.code;
          }
          return process.env.VUE_APP_I18N_LOCALE
        },
        // setter
        set: function (newValue) {
          this.$store.commit('mutateLocale', newValue);
        }
       },
      currencies: function () {
        if (this.$store.state.app.channel) {
          return this.$store.state.app.channel.currencies;
        } else {
          return null;
        }
      },
      locales: function (){
        if (this.$store.state.app.channel) {
          return this.$store.state.app.channel.locales;
        } else {
          return null;
        }
      },
      regionDialog: {
        // getter
        get: function () {
          return this.$store.state.app.regionDialog;
        },
        // setter
        set: function (newValue) {
          this.$store.commit('mutateRegionDialog', newValue);
        }
      }
    },
    async mounted () {
      // this.redirectToLocale();
      this.getChannel();
      // this.determineRegion(); No need to determine region. we only determine region and redirect on the /store path
    },
    methods: {
      redirectToLocale () {
        if (this.$store.state.app.locale) {
          if (this.$store.state.app.locale.code) {
            return this.$router.push('/' + this.$store.state.app.locale.code)
          }
        }
        return this.$router.push('/' + process.env.VUE_APP_I18N_LOCALE)
      },
      afterEnter () {
        this.$root.$emit('scrollAfterEnter');
      },
      currentYear() {
        var d = new Date();
        return d.getFullYear();
      },
      async switchCurrency(currencyCode) {
        if (currencyCode) {
          let currencyObject = await this.find(this.currencies, currencyCode);
          store.commit('mutateCurrency', currencyObject);
        } else {
          store.commit('mutateCurrency', this.find(this.currencies, this.applicationCurrency));
        }

        // location.reload();
      },
      async switchLocale(ianaCode) {
        if (ianaCode) {
          let localeObject = await this.find(this.locales, ianaCode);
          store.commit('mutateLocale', localeObject);
        } else {
          store.commit('mutateLocale', this.find(this.locales, this.applicationLocale));
        }
        // location.reload();
      },
      async find(haystack, key) {
        return haystack.find(x => x.code === key)
      },
      logout() {
        CustomerService.logCustomerOut()
        .then(response => {
            if (this.$route.meta.requiresAuth) {
              this.$router.push({ path:'/' })
            }
            // flash logout message

         }).catch(error => {
           console.log(error);
         })
      },
      async getSubCategories(parent_id) {
        CategoryService.methods.getSubCategories(parent_id)
        .then(listItems => {
            store.commit('mutateMenuItems', listItems);
            this.items = store.state.app.menuItems
         }).catch(error => {
           console.log(error);
         })
      },
      async getChannel() {
        ChannelService.getChannelByCode(process.env.VUE_APP_CHANNEL)
        .then(channel => {

          axios.interceptors.request.use((config) => {
              config.params = config.params || {};
              config.params['locale'] = this.$store.state.app.locale ? this.$store.state.app.locale.code : process.env.VUE_APP_I18N_LOCALE ;
              config.params['currency'] = this.$store.state.app.currency ? this.$store.state.app.currency.code : process.env.VUE_APP_DEFAULT_CURRENCY_CODE ;
              config.params['channel'] = this.$store.state.app.channel ? this.$store.state.app.channel.code : process.env.VUE_APP_CHANNEL ;

              return config;
          });

          this.ready = true;
          this.getSubCategories(channel.root_category_id);

         }).catch(error => {
           console.log(error);
         })
      },
      determineRegion() {
        let isIntl = process.env.VUE_APP_INTL;
        let localeSetByUser = this.$store.state.app.localeSetByUser;

        if (isIntl && !localeSetByUser) {  // if intl and intl in store is null (not ever set), ask user with
          this.regionDialog = true;
        }

      },
      selectRegion(ianaCode, currencyCode) {

        if (ianaCode == 'en_GB') {
          window.location.href = "https://dev.houstead.co.uk";
        }

        if (ianaCode == 'en_EU') {
          window.location.href = "https://dev.houstead.eu";
        }

        if (ianaCode == 'en_US') {
          window.location.href = "https://dev.houstead.com";
        }

      },
      reload() {
        location.reload();
      }
    }
  }
</script>

<style scss>

  html {
    font-family: 'Roboto Mono', monospace !important;
  }

  body {
    font-family: 'Roboto Mono', monospace !important;
  }

  .v-main__wrap {
      min-height:100vh;
  }

  h1,h2,h3,h4,h5 {
    font-family: 'Playfair Display', serif;
  }

  h1 {
    font-size: '20pt';
  }

  .v-toolbar__title {
    font-family: 'Playfair Display', serif;
    font-size: 20pt;
    font-weight: bold;
    color:FFF;
  }

/*
  .brand-text-red {
    color:#fc4c43;
    font-family: 'Roboto Mono', monospace !important;
  } */


  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

</style>
